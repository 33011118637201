import {NullOrUndefined} from '@synisys/skynet-store-utilities';
import {isNil} from 'lodash';

export class MoneyModel {
    public constructor(
        public amount: number | undefined,
        public currencyId: number | undefined
    ) {}
}

export function compareMoney(
    first: MoneyModel | NullOrUndefined,
    second: MoneyModel | NullOrUndefined
): boolean {
    if (isNil(first) || isNil(second)) {
        return isNil(first) && isNil(second);
    }
    return (
        first.amount === second.amount && first.currencyId === second.currencyId
    );
}

export function isMoneyNil(money: MoneyModel | NullOrUndefined): money is NullOrUndefined {
    return isNil(money) || (isNil(money.amount) && isNil(money.currencyId));
}
