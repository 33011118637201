import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Store} from '@ngrx/store';
import {MultilingualString} from '@synisys/idm-crosscutting-concepts-frontend';
import {FormattingService} from '@synisys/idm-frontend-shared';
import {
    CurrencyModel,
    CurrencyState,
    currencyStoreManager,
    getDefaultCurrency,
} from '@synisys/skynet-store-currencies-api';
import {notNil} from '@synisys/skynet-store-utilities';
import {isNil} from 'lodash';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Observable} from 'rxjs/Observable';
import {combineLatest} from 'rxjs/observable/combineLatest';
import {of} from 'rxjs/observable/of';
import {
    distinctUntilChanged,
    filter,
    first,
    map,
    startWith,
    switchMap,
    take,
    tap,
} from 'rxjs/operators';
import {ReplaySubject} from 'rxjs/ReplaySubject';
import {noop} from 'rxjs/util/noop';
import {createLogger, isPressedInFraction, simpleDebug} from '../../utilities';

@Component({
    moduleId: module.id + '',
    selector: 'sis-amount',
    templateUrl: 'sis-amount.component.html',
    styleUrls: ['sis-amount.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SisAmountComponent implements OnInit {
    @Input()
    public id: string;
    @Input()
    public isReadonly: boolean;
    public data$: Observable<{
        currency: CurrencyModel;
        fractionDigits: number;
        rateHint: string;
    }>;
    public currencySymbol$: Observable<string>;

    public rateHint$: Observable<string>;
    @Output()
    public amountChange = new EventEmitter<number | undefined>();
    @Input()
    public hint: MultilingualString | undefined;
    private logger = createLogger('sis-amount');
    private blur$ = new BehaviorSubject<boolean>(false);
    private amountControl = new FormControl('');

    private currencyId$ = new ReplaySubject<number | undefined>(1);
    private rateToDefault$ = new BehaviorSubject<number | undefined>(undefined);

    constructor(
        private store: Store<CurrencyState>,
        private formattingService: FormattingService
    ) {}

    @Input()
    set rateToDefault(value: number | undefined) {
        this.rateToDefault$.next(value);
    }

    @Input()
    set amount(inputAmount: number | undefined) {
        this.formattingService
            .parseNumber(this.amountControl.value)
            .pipe(
                first(),
                switchMap(current => {
                    if (current === inputAmount) {
                        return of<number>();
                    }
                    return this.formatAmount(inputAmount);
                }),
                take(1),
                tap(formattedInput =>
                    this.amountControl.setValue(formattedInput)
                )
            )
            .subscribe(noop, err => this.logger.error('%O', err));
    }

    public onBlur(): void {
        this.blur$.next(true);
    }

    @Input()
    set currencyId(currencyId: number | undefined) {
        this.currencyId$.next(currencyId);
    }

    public handleKeyPress(event: KeyboardEvent, fractionDigits): void {
        if (
            !/[0-9.,]/.test(event.key) ||
            isPressedInFraction(
                this.amountControl.value,
                fractionDigits,
                event.target
            )
        ) {
            event.preventDefault();
        }
    }

    public ngOnInit(): void {
        this.logger = createLogger('sis-amount:' + this.id);

        const fractionDigits$ = this.formattingService.getCurrentLocale().pipe(
            map(locale => locale.numberFormat.fractionDigits),
            first()
        );

        const valueChanges: Observable<string> = this.amountControl.valueChanges.pipe(
            startWith(this.amountControl.value)
        ); // Readonly issue

        const isReady$ = combineLatest(
            this.blur$,
            valueChanges.pipe(
                switchMap(amount =>
                    combineLatest(
                        this.formattingService.parseNumber(amount),
                        fractionDigits$
                    )
                ),
                distinctUntilChanged(
                    (a, b) => a === b,
                    ([parsedAmount, fractionDigits]) => {
                        return notNil(parsedAmount)
                            ? Math.floor(
                                  parsedAmount * Math.pow(10, fractionDigits)
                              )
                            : undefined;
                    }
                ),
                map(([parsedAmount, _]) => parsedAmount)
            )
        ).pipe(
            switchMap(([blur, parsedAmount]) => {
                if (!blur) {
                    this.amountChange.emit(parsedAmount);
                    return of<boolean>(true);
                }
                return this.formatAmount(parsedAmount).pipe(
                    tap(() => this.blur$.next(false)),
                    tap(formattedRate =>
                        this.amountControl.setValue(formattedRate)
                    ),
                    tap(() => this.amountChange.emit(parsedAmount)),
                    map(() => true)
                );
            })
        );

        this.currencySymbol$ = combineLatest(
            currencyStoreManager.selectAll(this.store),
            this.currencyId$.pipe(filter(notNil))
        ).pipe(
            map(([currencies, currencyId]) => currencies.get(currencyId)!),
            simpleDebug(this.logger, 'currency$ %O'),
            map(currency => currency.symbol)
        );

        this.rateHint$ = combineLatest(
            getDefaultCurrency(this.store),
            this.rateToDefault$,
            valueChanges.pipe(
                switchMap(amount => this.formattingService.parseNumber(amount))
            ),
            this.currencyId$
        ).pipe(
            switchMap(([defaultCurrency, rate, amount, currencyId]) => {
                if (
                    isNil(amount) ||
                    isNil(rate) ||
                    currencyId === defaultCurrency.id
                ) {
                    return of('');
                }
                return this.formattingService
                    .formatNumber(rate * amount)
                    .pipe(
                        map(
                            (formatted: string) =>
                                `= ${defaultCurrency.symbol} ${formatted}`
                        )
                    );
            }),
            simpleDebug(this.logger, 'rateHint$ %O')
        );

        this.data$ = combineLatest(
            currencyStoreManager.selectAll(this.store),
            this.currencyId$.pipe(filter(notNil)),
            fractionDigits$,
            this.rateHint$,
            isReady$
        ).pipe(
            map(([currencies, currencyId, fractionDigits, rateHint]) => ({
                currency: currencies.get(currencyId)!,
                fractionDigits,
                rateHint,
            })),
            simpleDebug(this.logger, 'data$ %O')
        );
    }

    private formatAmount(inputAmount: number | undefined): Observable<string> {
        if (isNil(inputAmount) || isNaN(inputAmount)) {
            return of<string>('');
        }
        return this.formattingService.formatNumber(inputAmount);
    }
}
