import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import {Store} from '@ngrx/store';
import {MultilingualString} from '@synisys/idm-crosscutting-concepts-frontend';
import {FormattingService} from '@synisys/idm-frontend-shared';
import {
    CurrencyModel,
    currencyRateStoreManager,
    CurrencyState,
    currencyStoreManager,
    getDefaultCurrency,
    keyExtractor,
} from '@synisys/skynet-store-currencies-api';
import {LocaleInfo} from '@synisys/skynet-store-locales-api';
import {deserializeMultilingual, notNil} from '@synisys/skynet-store-utilities';
import {isNil} from 'lodash';
import {Observable} from 'rxjs/Observable';
import {combineLatest} from 'rxjs/observable/combineLatest';
import {of} from 'rxjs/observable/of';
import {
    distinctUntilChanged,
    filter,
    first,
    map,
    publishReplay,
    refCount,
    startWith,
    switchMap,
    takeUntil,
    tap,
    withLatestFrom,
} from 'rxjs/operators';
import {ReplaySubject} from 'rxjs/ReplaySubject';
import {Subject} from 'rxjs/Subject';
import {noop} from 'rxjs/util/noop';
import {createLogger, simpleDebug} from '../../utilities';

export type RateWithDate = {rate: number | undefined; date: Date | undefined};

function compareRateWithDate(
    left: RateWithDate | undefined,
    right: RateWithDate | undefined
): boolean {
    if (isNil(left) || isNil(right)) {
        return isNil(left) && isNil(right);
    }
    return left.rate === right.rate && left.date === right.date;
}

@Component({
    moduleId: module.id + '',
    selector: 'sis-rate',
    templateUrl: 'sis-rate.component.html',
    styleUrls: ['sis-rate.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SisRateComponent implements OnInit, OnDestroy {
    @Output()
    public rateChange = new EventEmitter<RateWithDate>();

    public selectedCurrencyId$ = new ReplaySubject<number | undefined>(1);
    public rateReadOnlyData$: Observable<{
        rate: string;
        currency: CurrencyModel;
        defaultCurrency: CurrencyModel;
    }>;
    public viewRate$: Observable<{isCustom: boolean; rate: number | undefined}>;
    public rateHint$: Observable<string>;
    public withRateComponent$: Observable<boolean>;
    public formattedRateDate$: Observable<{
        formattedRateDate: string | undefined;
    }>;
    public localeInfo$: Observable<LocaleInfo>;
    // TODO: replace this when specialized message is added for de
    public readonly datePlaceholderKey =
        'portfolio.filter.date.range.choose.date';
    public readonly defaultPlaceholder = 'Choose a date';
    public rateByDate$ = new ReplaySubject<
        {date: Date; rate: number} | undefined
    >(1);

    private _id: string;
    private actualRate$ = new ReplaySubject<number | undefined>(1);
    private rateWithDateInput$ = new ReplaySubject<RateWithDate>(1);
    private formattedRate$: Observable<string>;
    private date$ = new ReplaySubject<Date | undefined>(1);

    private destructionSubject = new Subject<void>();
    private _rateDateReadOnly: boolean;
    private _rateReadOnly: boolean;
    private _date: Date | undefined;
    private _rateLabel: string | MultilingualString | undefined;
    private _dateLabel: string | MultilingualString | undefined;
    private logger = createLogger('sis-rate');

    private emitValue = true;

    // <editor-fold desc="getters and setters">

    @Input()
    set id(value: string) {
        this._id = value;
        this.logger = createLogger('sis-rate:' + this._id);
    }

    @Input()
    set rate(value: RateWithDate) {
        this.rateWithDateInput$.next(value);
    }

    get rateDateReadOnly(): boolean {
        return this._rateDateReadOnly;
    }

    @Input()
    set rateDateReadOnly(value: boolean) {
        this._rateDateReadOnly = value;
    }

    get rateReadOnly(): boolean {
        return this._rateReadOnly;
    }

    @Input()
    set rateReadOnly(value: boolean) {
        this._rateReadOnly = value;
    }

    @Input()
    set selectedCurrencyId(value: number | undefined) {
        this.selectedCurrencyId$.next(value);
    }

    get rateLabel(): string | MultilingualString | undefined {
        return this._rateLabel;
    }

    @Input()
    set rateLabel(value: string | MultilingualString | undefined) {
        if (value) {
            this._rateLabel = deserializeMultilingual(value);
        }
    }

    get dateLabel(): string | MultilingualString | undefined {
        return this._dateLabel;
    }

    @Input()
    set dateLabel(value: string | MultilingualString | undefined) {
        if (value) {
            this._dateLabel = deserializeMultilingual(value);
        }
    }

    get date(): Date | undefined {
        return this._date;
    }

    set date(date: Date | undefined) {
        if (notNil(date)) {
            date = new Date(
                date.getTime() - date.getTimezoneOffset() * 60 * 1000
            ); // to UTC date
        }
        this._date = date;
        this.date$.next(date);
    }

    // </editor-fold>

    constructor(
        private store: Store<CurrencyState>,
        private formattingService: FormattingService
    ) {}

    public ngOnInit(): void {
        this.localeInfo$ = this.formattingService.getCurrentLocale();

        this.formattedRateDate$ = this.date$.pipe(
            switchMap(date => {
                if (isNil(date)) {
                    return of(undefined);
                }
                return this.formattingService.formatDate(date.getTime());
            }),
            map(formattedRateDate => ({formattedRateDate}))
        );

        this.rateWithDateInput$
            .pipe(
                distinctUntilChanged<RateWithDate>(compareRateWithDate),
                simpleDebug(this.logger, 'rateWithDateInput$ %O'),
                switchMap(({rate, date}) => {
                    this.emitValue = false;
                    this._date = date;
                    this.date$.next(date);
                    if (isNil(date)) {
                        return of(rate);
                    }
                    return this.rateByDate$.pipe(
                        filter(notNil),
                        first(rateByDate => rateByDate.date === date),
                        map(() => rate)
                    );
                }),
                tap(() => (this.emitValue = false)),
                tap(actualRate => this.actualRate$.next(actualRate)),
                takeUntil(this.destructionSubject)
            )
            .subscribe(noop, err => this.logger.error('%O', err));

        this.formattedRate$ = this.actualRate$.pipe(
            distinctUntilChanged(),
            switchMap(actualRate => {
                if (isNil(actualRate)) {
                    return '';
                }
                return this.formattingService.formatNumber(actualRate, {
                    fractionDigits: 4,
                });
            }),
            simpleDebug(this.logger, 'formattedRate$ %O')
        );

        this.viewRate$ = combineLatest(
            this.rateByDate$.pipe(
                distinctUntilChanged(compareRateWithDate),
                simpleDebug(this.logger, 'rateByDate$ %O'),
                map(rateByDate => {
                    if (isNil(rateByDate)) {
                        return undefined;
                    }
                    return rateByDate.rate;
                })
            ),
            this.actualRate$.pipe(
                distinctUntilChanged(),
                simpleDebug(this.logger, 'actualRate$ %O')
            )
        ).pipe(
            map(([rateByDate, actualRate]) => ({
                rate: actualRate,
                isCustom: notNil(rateByDate) && rateByDate !== actualRate,
            })),
            simpleDebug(this.logger, 'viewRate$ %O')
        );

        this.date$
            .pipe(
                distinctUntilChanged(),
                simpleDebug(this.logger, 'date$ %O'),
                filter(isNil),
                withLatestFrom(this.actualRate$),
                tap(([_, actualRate]) => {
                    this.rateByDate$.next(undefined);
                    this.rateChange.emit({
                        date: undefined,
                        rate: actualRate,
                    });
                }),
                takeUntil(this.destructionSubject)
            )
            .subscribe(noop, err => this.logger.error('%O', err));

        combineLatest(
            this.selectedCurrencyId$.pipe(
                distinctUntilChanged(),
                simpleDebug(this.logger, 'selectedCurrencyId$ %O'),
                filter(notNil),
                withLatestFrom(
                    this.viewRate$.pipe(startWith({isCustom: false}))
                ),
                filter(([_, rateView]) => !rateView.isCustom),
                map(([currencyId, _]) => currencyId)
            ),
            this.date$.pipe(distinctUntilChanged(), filter(notNil))
        )
            .pipe(
                switchMap(([currencyId, date]) =>
                    currencyRateStoreManager
                        .selectOne(
                            this.store,
                            keyExtractor({
                                timestamp: date.getTime(),
                                currencyId,
                            })
                        )
                        .pipe(
                            simpleDebug(this.logger, 'rate by date %O'),
                            filter(notNil),
                            map(currencyRate => currencyRate.rate),
                            map(rate => ({date, rate}))
                        )
                ),
                tap(({date, rate}) => {
                    if (this.emitValue) {
                        this.rateChange.emit({rate, date});
                    }
                    this.actualRate$.next(rate);
                    this.rateByDate$.next({rate, date});
                }),
                takeUntil(this.destructionSubject)
            )
            .subscribe(noop, err => this.logger.error('%O', err));

        const defaultCurrency$ = getDefaultCurrency(this.store);

        this.rateReadOnlyData$ = combineLatest(
            this.selectedCurrencyId$.pipe(
                distinctUntilChanged(),
                filter(notNil),
                switchMap(id =>
                    currencyStoreManager
                        .selectOne(this.store, id)
                        .pipe(
                            tap(
                                currency =>
                                    isNil(currency) &&
                                    this.logger.error(
                                        'Cannot find currency by currencyId %O',
                                        id
                                    )
                            )
                        )
                ),
                filter(notNil)
            ),
            this.formattedRate$,
            defaultCurrency$
        ).pipe(
            map(([currency, rate, defaultCurrency]) => ({
                currency,
                rate,
                defaultCurrency,
            })),
            simpleDebug(this.logger, 'rateReadOnlyData$ %O')
        );

        this.rateHint$ = defaultCurrency$.pipe(
            map(currencyRate => `against ${currencyRate.abbreviation}`),
            publishReplay<string>(1),
            refCount()
        );

        this.withRateComponent$ = combineLatest(
            defaultCurrency$,
            this.selectedCurrencyId$
        ).pipe(
            map(
                ([defaultCurrency, accounting]) =>
                    defaultCurrency.id !== accounting
            ),
            simpleDebug(this.logger, 'withRateComponent$ %O'),
            publishReplay<boolean>(1),
            refCount()
        );
    }

    public rateInput(inputRate: number | undefined): void {
        if (notNil(inputRate)) {
            inputRate = Number(inputRate.toFixed(4));
        }
        this.rateChange.emit({rate: inputRate, date: this._date});
        this.actualRate$.next(inputRate);
    }

    public reset(rateByDate: RateWithDate): void {
        this.rateChange.emit(rateByDate);
        this.actualRate$.next(rateByDate.rate);
    }

    public ngOnDestroy(): void {
        this.destructionSubject.next();
        this.destructionSubject.complete();
    }
}
