import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import {Store} from '@ngrx/store';
import {MultilingualString} from '@synisys/idm-crosscutting-concepts-frontend';
import {
    CurrencyState,
    getDefaultCurrency,
} from '@synisys/skynet-store-currencies-api';
import {deserializeMultilingual} from '@synisys/skynet-store-utilities';
import {Observable} from 'rxjs/Observable';
import {of} from 'rxjs/observable/of';
import {distinctUntilChanged, map, switchMap, tap} from 'rxjs/operators';
import {ReplaySubject} from 'rxjs/ReplaySubject';
import {compareMoney, isMoneyNil, MoneyModel} from './money.model';

@Component({
    moduleId: module.id + '',
    selector: 'sis-money',
    templateUrl: 'sis-money.component.html',
    styleUrls: ['sis-money.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SisMoneyComponent implements OnInit {
    @Input()
    public isReadonly: boolean;
    @Output()
    public moneyChange = new EventEmitter<MoneyModel>();
    public isReady$: Observable<boolean>;

    private money$ = new ReplaySubject<MoneyModel | undefined>(1);

    private _id: string;
    private _money: MoneyModel;
    private _hint: MultilingualString | undefined;

    // <editor-fold desc="getters and setters">
    get id(): string {
        return this._id;
    }

    @Input()
    set id(value: string) {
        this._id = value;
    }

    @Input()
    set money(value: MoneyModel | undefined) {
        this.money$.next(value);
    }

    get amount(): number | undefined {
        return this._money.amount;
    }

    set amount(amount: number | undefined) {
        this.money$.next(new MoneyModel(amount, this.currencyId));
    }

    get currencyId(): number | undefined {
        return this._money.currencyId;
    }

    set currencyId(currencyId: number | undefined) {
        this.money$.next(new MoneyModel(this.amount, currencyId));
    }

    get hint(): MultilingualString | undefined {
        return this._hint;
    }

    @Input()
    set hint(multilingualString: MultilingualString | undefined) {
        this._hint = deserializeMultilingual(multilingualString);
    }

    // </editor-fold>

    constructor(private readonly store: Store<CurrencyState>) {}

    public ngOnInit(): void {
        this.isReady$ = this.money$.pipe(
            distinctUntilChanged(compareMoney),
            switchMap(money => {
                if (!isMoneyNil(money)) {
                    return of(money);
                }
                return getDefaultCurrency(this.store).pipe(
                    map(
                        defaultCurrency =>
                            new MoneyModel(undefined, defaultCurrency.id)
                    )
                );
            }),
            tap(money => {
                this._money = money;
                this.moneyChange.emit(money);
            }),
            map(() => true)
        );
    }
}
