import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatFormFieldModule, MatSelectModule} from '@angular/material';
import {
    SisTranslateModule,
    SisUtilitiesModule,
} from '@synisys/idm-frontend-shared';
import {CurrencyStoreModule} from '@synisys/skynet-store-currencies-impl';
import {SisCurrencyComboComponent} from './sis-currency-combo.component';

@NgModule({
    declarations: [SisCurrencyComboComponent],
    exports: [SisCurrencyComboComponent],
    imports: [
        CommonModule,
        FormsModule,
        MatFormFieldModule,
        MatSelectModule,
        SisTranslateModule,
        SisUtilitiesModule,
        CurrencyStoreModule,
    ],
})
export class SisCurrencyComboModule {}
