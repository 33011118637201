import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {SisUtilitiesModule} from '@synisys/idm-frontend-shared';
import {CurrencyStoreModule} from '@synisys/skynet-store-currencies-impl';
import {SisAmountModule} from '../amount/index';
import {SisCurrencyComboModule} from '../currency-combo/index';
import {SisRateModule} from '../rate/index';
import {SisAccountingComponent} from './sis-accounting.component';

@NgModule({
    declarations: [SisAccountingComponent],
    exports: [SisAccountingComponent],
    imports: [
        SisAmountModule,
        SisCurrencyComboModule,
        CommonModule,
        FormsModule,
        SisRateModule,
        SisUtilitiesModule,
        CurrencyStoreModule,
    ],
})
export class SisAccountingModule {}
