import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {
    MatButtonModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    MatTooltipModule,
} from '@angular/material';
import {
    SisTranslateModule,
    SisUtilitiesModule,
} from '@synisys/idm-frontend-shared';
import {CurrencyStoreModule} from '@synisys/skynet-store-currencies-impl';
import {RateInputComponent} from './rate-input/index';
import {SisRateComponent} from './sis-rate.component';
import {ControlsModule} from '@synisys/idm-ng-controls';

@NgModule({
    declarations: [SisRateComponent, RateInputComponent],
    exports: [SisRateComponent],
    imports: [
        CommonModule,
        FormsModule,
        MatInputModule,
        MatFormFieldModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatButtonModule,
        MatTooltipModule,
        SisUtilitiesModule,
        SisTranslateModule,
        ReactiveFormsModule,
        ControlsModule,
        CurrencyStoreModule,
    ],
})
export class SisRateModule {}
