import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule, MatInputModule} from '@angular/material';
import {
    SisTranslateModule,
    SisUtilitiesModule,
} from '@synisys/idm-frontend-shared';
import {CurrencyStoreModule} from '@synisys/skynet-store-currencies-impl';
import {SisAmountComponent} from './sis-amount.component';

@NgModule({
    declarations: [SisAmountComponent],
    exports: [SisAmountComponent],
    imports: [
        CommonModule,
        FormsModule,
        MatFormFieldModule,
        SisTranslateModule,
        MatInputModule,
        SisUtilitiesModule,
        ReactiveFormsModule,
        CurrencyStoreModule,
    ],
})
export class SisAmountModule {}
