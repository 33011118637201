import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {
    SisTranslateModule,
    SisUtilitiesModule,
} from '@synisys/idm-frontend-shared';
import {CurrencyStoreModule} from '@synisys/skynet-store-currencies-impl';
import {SisAmountModule} from '../amount/index';
import {SisCurrencyComboModule} from '../currency-combo/index';
import {SisMoneyComponent} from './sis-money.component';

@NgModule({
    declarations: [SisMoneyComponent],
    exports: [SisMoneyComponent],
    imports: [
        CommonModule,
        SisCurrencyComboModule,
        SisAmountModule,
        SisTranslateModule,
        SisUtilitiesModule,
        CurrencyStoreModule,
    ],
})
export class SisMoneyModule {}
