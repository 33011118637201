import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import {FormControl} from '@angular/forms';
import {FormattingService} from '@synisys/idm-frontend-shared';
import {notNil} from '@synisys/skynet-store-utilities';
import {isNil} from 'lodash';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Observable} from 'rxjs/Observable';
import {combineLatest} from 'rxjs/observable/combineLatest';
import {of} from 'rxjs/observable/of';
import {
    distinctUntilChanged,
    first,
    map,
    startWith,
    switchMap,
    take,
    tap,
} from 'rxjs/operators';
import {noop} from 'rxjs/util/noop';
import {createLogger, isPressedInFraction} from '../../../utilities';
import {RateWithDate} from '../sis-rate.component';

const fractionDigits = 4;

@Component({
    moduleId: module.id + '',
    selector: 'rate-input',
    templateUrl: 'rate-input.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RateInputComponent implements OnInit {
    @Input()
    public hint: string;
    @Input()
    public isCustom: boolean;
    @Input()
    public rateByDate: {date: Date; rate: number} | undefined;
    @Output()
    public resetRate = new EventEmitter<RateWithDate>();
    @Output()
    public rateChange = new EventEmitter<number | undefined>();
    public isReady$: Observable<boolean>;
    public rateControl = new FormControl('');
    private blur$ = new BehaviorSubject<boolean>(false);
    private logger = createLogger('rate-input');

    @Input()
    set rate(inputRate: number | undefined) {
        this.formattingService
            .parseNumber(this.rateControl.value)
            .pipe(
                first(),
                switchMap(current => {
                    if (current === inputRate) {
                        return of<number>();
                    }
                    return this.formatRate(inputRate);
                }),
                take(1),
                tap(formattedInput => this.rateControl.setValue(formattedInput))
            )
            .subscribe(noop, err => this.logger.error('%O', err));
    }

    constructor(private formattingService: FormattingService) {}

    public handleKeyPress(event: KeyboardEvent): void {
        if (
            !/[0-9.,]/.test(event.key) ||
            isPressedInFraction(
                this.rateControl.value,
                fractionDigits,
                event.target
            )
        ) {
            event.preventDefault();
        }
    }

    public onBlur(): void {
        this.blur$.next(true);
    }

    public reset(rateByDate: RateWithDate): void {
        this.resetRate.emit(rateByDate);
    }

    public ngOnInit(): void {
        const valueChanges: Observable<string> = this.rateControl.valueChanges.pipe(
            startWith(this.rateControl.value)
        ); // Readonly issue

        this.isReady$ = combineLatest(
            this.blur$,
            valueChanges.pipe(
                switchMap(rate => this.formattingService.parseNumber(rate)),
                distinctUntilChanged(
                    (a, b) => a === b,
                    v => {
                        return notNil(v)
                            ? Math.floor(v * Math.pow(10, fractionDigits))
                            : undefined;
                    }
                )
            )
        ).pipe(
            switchMap(([blur, parsedRate]) => {
                if (!blur) {
                    this.rateChange.emit(parsedRate);
                    return of<boolean>(true);
                }
                return this.formatRate(parsedRate).pipe(
                    tap(() => this.blur$.next(false)),
                    tap(formattedRate =>
                        this.rateControl.setValue(formattedRate)
                    ),
                    tap(() => this.rateChange.emit(parsedRate)),
                    map(() => true)
                );
            })
        );
    }

    private formatRate(number: number | undefined): Observable<string> {
        if (isNil(number) || isNaN(number)) {
            return of<string>('');
        }
        return this.formattingService.formatNumber(number, {fractionDigits});
    }
}
