import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import {Store} from '@ngrx/store';
import {MultilingualString} from '@synisys/idm-crosscutting-concepts-frontend';
import {
    CurrencyModel,
    CurrencyState,
    getDefaultCurrency,
} from '@synisys/skynet-store-currencies-api';
import {deserializeMultilingual} from '@synisys/skynet-store-utilities';
import {isNil} from 'lodash';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Observable} from 'rxjs/Observable';
import {combineLatest} from 'rxjs/observable/combineLatest';
import {of} from 'rxjs/observable/of';
import {distinctUntilChanged, map, switchMap} from 'rxjs/operators';
import {ReplaySubject} from 'rxjs/ReplaySubject';
import {
    createLogger,
    isCustomRate,
    simpleDebug,
    todayUTC,
} from '../../utilities';
import {MoneyModel} from '../money/index';
import {
    AccountingModel,
    compareAccounting,
    isAccountingNil,
} from './accounting.model';

@Component({
    moduleId: module.id + '',
    selector: 'sis-accounting',
    templateUrl: 'sis-accounting.component.html',
    styleUrls: ['sis-accounting.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SisAccountingComponent implements OnInit {
    @Output()
    public accountingChange = new EventEmitter<AccountingModel>();
    public accounting$ = new ReplaySubject<AccountingModel | undefined>(1);
    public _accounting: AccountingModel;
    public isReady$: Observable<AccountingModel>;
    public defaultCurrency$ = getDefaultCurrency(this.store);

    private inheritFrom$ = new BehaviorSubject<AccountingModel | undefined>(
        undefined
    );
    private inherited = false;
    private _id: string;
    private _isReadonly: boolean;
    private _rateLabel: string | MultilingualString;
    private _dateLabel: string | MultilingualString;
    private _currencyReadOnly: boolean;
    private _rateDateReadOnly: boolean;
    private _rateReadOnly: boolean;
    private _hint: MultilingualString | undefined;
    private logger = createLogger('sis-accounting');

    // <editor-fold desc="getters and setters">
    get id(): string {
        return this._id;
    }

    @Input()
    set id(value: string) {
        this._id = value;
    }

    get isReadonly(): boolean {
        return this._isReadonly;
    }

    @Input()
    set isReadonly(value: boolean) {
        this._isReadonly = value;
    }

    @Input()
    set accounting(value: AccountingModel) {
        if (!compareAccounting(this._accounting, value) || isNil(value)) {
            this.accounting$.next(value);
        }
    }

    @Input()
    set inheritFrom(value: AccountingModel | undefined) {
        this.inheritFrom$.next(value);
        this.inherited = !isAccountingNil(value);
    }

    set rateDate(value: {rate: number | undefined; date: Date | undefined}) {
        if (
            value.rate === this._accounting.rateToDefault &&
            value.date === this._accounting.accountingDate
        ) {
            return;
        }
        this.accounting$.next(
            new AccountingModel(
                value.rate,
                this._accounting.originalMoney,
                value.date
            )
        );
    }

    set originalMoneyAmount(value: number | undefined) {
        if (value === this._accounting.originalMoney.amount) {
            return;
        }
        this.accounting$.next(
            new AccountingModel(
                this._accounting.rateToDefault,
                new MoneyModel(
                    value,
                    this._accounting.originalMoney.currencyId
                ),
                this._accounting.accountingDate
            )
        );
    }

    get rateLabel(): string | MultilingualString {
        return this._rateLabel;
    }

    @Input()
    set rateLabel(value: string | MultilingualString) {
        this._rateLabel = value;
    }

    get dateLabel(): string | MultilingualString {
        return this._dateLabel;
    }

    @Input()
    set dateLabel(value: string | MultilingualString) {
        this._dateLabel = value;
    }

    get currencyReadOnly(): boolean {
        return this._currencyReadOnly;
    }

    @Input()
    set currencyReadOnly(value: boolean) {
        this._currencyReadOnly = value;
    }

    get rateDateReadOnly(): boolean {
        return this._rateDateReadOnly;
    }

    @Input()
    set rateDateReadOnly(value: boolean) {
        this._rateDateReadOnly = value;
    }

    get rateReadOnly(): boolean {
        return this._rateReadOnly;
    }

    @Input()
    set rateReadOnly(value: boolean) {
        this._rateReadOnly = value;
    }

    get hint(): MultilingualString | undefined {
        return this._hint;
    }

    @Input()
    set hint(multilingualString: MultilingualString | undefined) {
        this._hint = deserializeMultilingual(multilingualString);
    }

    // </editor-fold>

    constructor(private readonly store: Store<CurrencyState>) {}

    public ngOnInit(): void {
        this.logger = createLogger('sis-accounting:' + this.id);
        this.isReady$ = combineLatest(
            this.accounting$.pipe(
                simpleDebug(this.logger, 'accounting input %O'),
                distinctUntilChanged(compareAccounting)
            ),
            this.inheritFrom$.pipe(
                simpleDebug(this.logger, 'parent accounting input %O'),
                distinctUntilChanged(compareAccounting)
            )
        ).pipe(
            switchMap(([accounting, inherited]) => {
                if (isAccountingNil(inherited)) {
                    return of(accounting);
                }
                if (this.rateReadOnly) {
                    return of(
                        new AccountingModel(
                            inherited.rateToDefault,
                            new MoneyModel(
                                isNil(accounting)
                                    ? 0
                                    : accounting.originalMoney.amount,
                                inherited.originalMoney.currencyId
                            ),
                            inherited.accountingDate
                        )
                    );
                } else {
                    if (isAccountingNil(accounting)) {
                        return of(
                            new AccountingModel(
                                inherited.rateToDefault,
                                new MoneyModel(
                                    0,
                                    inherited.originalMoney.currencyId
                                ),
                                inherited.accountingDate
                            )
                        );
                    }
                    return combineLatest(
                        isCustomRate(
                            accounting.rateToDefault,
                            accounting.originalMoney.currencyId!,
                            accounting.accountingDate,
                            this.store
                        ),
                        isCustomRate(
                            inherited.rateToDefault,
                            inherited.originalMoney.currencyId!,
                            inherited.accountingDate,
                            this.store
                        )
                    ).pipe(
                        map(([accountingIsCustom, inheritedIsCustom]) =>
                            !accountingIsCustom && !inheritedIsCustom
                                ? inherited.rateToDefault
                                : accounting.rateToDefault
                        ),
                        map(
                            rate =>
                                new AccountingModel(
                                    rate,
                                    new MoneyModel(
                                        accounting.originalMoney.amount,
                                        inherited.originalMoney.currencyId
                                    ),
                                    inherited.accountingDate
                                )
                        )
                    );
                }
            }),
            switchMap(accounting => {
                return this.defaultCurrency$.pipe(
                    map(defaultCurrency => {
                        if (!isAccountingNil(accounting)) {
                            return accounting;
                        }
                        return new AccountingModel(
                            1,
                            new MoneyModel(undefined, defaultCurrency.id),
                            todayUTC()
                        );
                    })
                );
            }),
            simpleDebug(this.logger, 'accounting output %O'),
            map(accounting => {
                this._accounting = accounting;
                this.accounting = accounting;
                this.accountingChange.emit(accounting);
                return accounting;
            })
        );
    }

    public setOriginalMoneyCurrencyId(
        value: number | undefined,
        defaultCurrencyId: number
    ): void {
        if (value === this._accounting.originalMoney.currencyId) {
            return;
        }
        this.accounting$.next(
            new AccountingModel(
                value === defaultCurrencyId
                    ? 1
                    : this._accounting.rateToDefault,
                new MoneyModel(this._accounting.originalMoney.amount, value),
                value === defaultCurrencyId
                    ? todayUTC()
                    : this._accounting.accountingDate
            )
        );
    }

    public isCurrencyComboVisible(): boolean {
        return !this._isReadonly && !this._currencyReadOnly && !this.inherited;
    }

    public isRateComponentVisible(): boolean {
        return !(this._isReadonly || (this.inherited && this.rateReadOnly));
    }

    public isRateDateReadonly(): boolean {
        return this._rateDateReadOnly || this.inherited;
    }
}
