import {Store} from '@ngrx/store';
import {
    currencyRateStoreManager,
    keyExtractor,
} from '@synisys/skynet-store-currencies-api';
import {Logger, notNil, NullOrUndefined} from '@synisys/skynet-store-utilities';
import {isNil} from 'lodash';
import {MonoTypeOperatorFunction} from 'rxjs/interfaces';
import {Observable} from 'rxjs/Observable';
import {of} from 'rxjs/observable/of';
import {filter, map, tap} from 'rxjs/operators';

export function simpleDebug<T>(
    logger: Logger,
    message: string
): MonoTypeOperatorFunction<T> {
    return tap(data => logger.debug(message, data));
}

export function isPressedInFraction(
    value: string,
    fractionDigits: number,
    eventTarget: EventTarget | NullOrUndefined
): boolean {
    if (!value || isNil(eventTarget)) {
        return false;
    }
    const fraction = value.split('.')[1];
    if (isNil(fraction)) {
        return false;
    }
    if (fraction.length < fractionDigits) {
        return false;
    }
    const selectionStart = (eventTarget as HTMLInputElement).selectionStart;
    if (isNil(selectionStart)) {
        return false;
    }
    return value.indexOf('.') < selectionStart;
}

export function todayUTC(): Date {
    const today = new Date();
    return new Date(
        Date.UTC(today.getFullYear(), today.getMonth(), today.getDate())
    );
}

export function createLogger(scope: string): Logger {
    return new Logger('idm-currency-rate-frontend', scope);
}

export function isCustomRate(
    rate: number | undefined,
    currencyId: number,
    date: Date | undefined,
    store: Store<object>
): Observable<boolean> {
    if (isNil(date)) {
        return of(true);
    }
    return currencyRateStoreManager
        .selectOne(
            store,
            keyExtractor({
                timestamp: date.getTime(),
                currencyId,
            })
        )
        .pipe(
            filter(notNil),
            map(rateByDate => rate !== rateByDate.rate)
        );
}
