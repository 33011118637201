import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import {Store} from '@ngrx/store';
import {
    CurrencyModel,
    CurrencyState,
    currencyStoreManager,
} from '@synisys/skynet-store-currencies-api';
import {notNil} from '@synisys/skynet-store-utilities';
import {List} from 'immutable';
import {Observable} from 'rxjs/Observable';
import {map} from 'rxjs/operators';

@Component({
    moduleId: module.id + '',
    selector: 'sis-currency-combo',
    templateUrl: 'sis-currency-combo.component.html',
    styleUrls: ['sis-currency-combo.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SisCurrencyComboComponent implements OnInit {
    @Input()
    public id: string;
    @Output()
    public currencyIdChange = new EventEmitter<number>();
    private _currencies$: Observable<List<CurrencyModel>>;
    private _currencyId: number;

    @Input()
    set currencyId(currencyId: number) {
        if (this._currencyId === currencyId) {
            return;
        }
        if (notNil(this._currencyId)) {
            this.currencyIdChange.emit(currencyId);
        }
        this._currencyId = currencyId;
    }

    get currencyId(): number {
        return this._currencyId;
    }

    get currencies$(): Observable<List<CurrencyModel>> {
        return this._currencies$;
    }

    constructor(private store: Store<CurrencyState>) {}

    public ngOnInit(): void {
        this._currencies$ = currencyStoreManager
            .selectAll(this.store)
            .pipe(map(value => value.toList()));
    }

    public trackByCurrency(index: number, currency: CurrencyModel): number {
        return currency.id;
    }
}
