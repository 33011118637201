import {NullOrUndefined} from '@synisys/skynet-store-utilities';
import {isNil} from 'lodash';
import {compareMoney, isMoneyNil, MoneyModel} from '../money/money.model';

export class AccountingModel {
    public constructor(
        public rateToDefault: number | undefined,
        public originalMoney: MoneyModel,
        public accountingDate: Date | undefined
    ) {}
}

export function compareAccounting(
    first: AccountingModel | NullOrUndefined,
    second: AccountingModel | NullOrUndefined
): boolean {
    if (isNil(first) || isNil(second)) {
        return isNil(first) && isNil(second);
    }
    return (
        compareMoney(first.originalMoney, second.originalMoney) &&
        first.rateToDefault === second.rateToDefault &&
        first.accountingDate === second.accountingDate
    );
}

export function isAccountingNil(
    accounting: AccountingModel | NullOrUndefined
): accounting is NullOrUndefined {
    return (
        isNil(accounting) ||
        (isNil(accounting.accountingDate) &&
            isNil(accounting.rateToDefault) &&
            isMoneyNil(accounting.originalMoney))
    );
}
